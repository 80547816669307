import { GetPageResult } from "results/content/GetPageResult";
import { GetTeamMembersResult } from "results/content/GetTeamMembersResult";
import { useApiService } from "services/ApiService";

export const ContentApiService = () => {
    const { get } = useApiService({ controller: "/content" });

    return {
        getTerms: () => get<GetPageResult>("/terms"),
        getPrivacy: () => get<GetPageResult>("/privacy"),
        getTeam: () => get<GetTeamMembersResult>("/team"),
    };
};
