import { ProfileResult } from "results/users/ProfileResult";
import { useApiService } from "services/ApiService";

export const UsersApiService = () => {
    const { get } = useApiService({ controller: "/users" });

    return {
        getProfile: () => get<ProfileResult>("/profile"),
    };
};
