export const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) {
        month = `0${month}`;
    }
    if (day.length < 2) {
        day = `0${day}`;
    }

    return [year, month, day].join("-");
};

export const getCurrentShortDate = () => new Intl.DateTimeFormat("en-AU", { dateStyle: "medium" }).format(new Date());
export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getStartedDescription = () => `Started ${getCurrentShortDate()}`;
export const getInvitedDescription = () => `Created ${getCurrentShortDate()}`;
export const getPurchaseDescription = () => `Purchased ${getCurrentShortDate()}`;
