import { PurchaseSchoolSubscriptionCommand } from "commands/subscriptions/PurchaseSchoolSubscriptionCommand";
import { PurchaseResult } from "results/PurchaseResult";
import { useApiService } from "services/ApiService";

export const PaymentsApiService = () => {
    const { post } = useApiService({ controller: "/payments" });

    return {
        purchaseSchoolSubscriptions: (command: PurchaseSchoolSubscriptionCommand) =>
            post<PurchaseResult>("/generate", command),
    };
};
