import { BreadcrumbList } from "data/BreadcrumbsData";
import { Link } from "react-router-dom";
import { RouteList } from "Routes";

export const Breadcrumbs = (props: { breadcrumbs: BreadcrumbList }) => {
    const { breadcrumbs } = props;
    const { current, links } = breadcrumbs;

    return (
        <div className="container">
            <ol className="breadcrumb mb-n4">
                <li className="breadcrumb-item">
                    {!current ? <span>Home</span> : <Link to={RouteList.Home}>Home</Link>}
                </li>

                {links?.map(l => (
                    <li key={l.url} className="breadcrumb-item text-break">
                        <Link to={l.url}>{l.text}</Link>
                    </li>
                ))}

                <li className="breadcrumb-item active text-break">{current}</li>
            </ol>
        </div>
    );
};
