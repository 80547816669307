import WritelikeLogo from "assets/img/logos/writelike-white.svg";
import FrankenstoriesFrankyLogo from "assets/img/logos/frankenstories-franky.svg";
import FrankenstoriesNameLogo from "assets/img/logos/frankenstories-name.svg";
import { Link } from "react-router-dom";
import { RouteList } from "Routes";
import { Constants } from "utils/Constants";

const FrankenstoriesLogo = () => (
    <div className="hstack gap-2">
        <img src={FrankenstoriesFrankyLogo} alt="Frankenstories" style={{ width: 56 }} />
        <img src={FrankenstoriesNameLogo} alt="Frankenstories" style={{ width: 125 }} />
    </div>
);

export const Prenav = () => (
    <div className="py-3 bg-dark text-white">
        <div className="container">
            <div className="hstack gap-3 justify-content-between">
                <div>
                    <Link className="navbar-brand" to={RouteList.Home}>
                        School admin
                    </Link>
                </div>

                <div className="hstack gap-4 d-none d-lg-flex">
                    <a href={Constants.frankenstoriesUrl} target="_blank" rel="noreferrer">
                        <FrankenstoriesLogo />
                    </a>

                    <a href={Constants.webUrl} target="_blank" rel="noreferrer">
                        <img src={WritelikeLogo} alt="Writelike" style={{ width: 100 }} />
                    </a>
                </div>
            </div>
        </div>
    </div>
);
