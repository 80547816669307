import { Modal } from "bootstrap";
import clsx from "clsx";
import { IconText } from "components/IconText";
import { SpinnerBlock } from "components/Spinner";
import { Fragment, ReactNode, useEffect, useRef } from "react";

export const ModalDialog = (props: {
    children: ReactNode;
    title: string;
    id: string;
    confirmBtnText?: string;
    confirmBtnIcon?: string;
    confirmBtnClass?: string;
    confirmBtnType?: "button" | "submit";
    size?: "md" | "lg";
    loading?: boolean;
    successMessage?: ReactNode;
    onConfirm?: () => void;
    onClose?: () => void;
    disabled?: boolean;
    secondaryBtnClass?: string;
    secondaryText?: string;
    secondaryIcon?: string;
    onSecondaryConfirm?: () => void;
}) => {
    const {
        children,
        id,
        title,
        confirmBtnText,
        confirmBtnType,
        confirmBtnIcon,
        size = "md",
        confirmBtnClass = "btn-primary",
        loading,
        successMessage,
        onConfirm,
        onClose,
        disabled,
        secondaryBtnClass,
        secondaryIcon,
        secondaryText,
        onSecondaryConfirm,
    } = props;

    const modalRef = useRef<HTMLDivElement>(null);

    // stash close event
    const handleClose = useRef<() => void>();

    useEffect(() => {
        handleClose.current = onClose;
    }, [onClose]);

    // close modal on unmount
    useEffect(() => {
        if (modalRef.current) {
            const modal = Modal.getOrCreateInstance(modalRef.current);

            // on close event
            if (handleClose.current) {
                modalRef.current.addEventListener("hidden.bs.modal", () => {
                    if (handleClose.current) {
                        handleClose.current();
                    }
                });
            }

            return () => {
                modal.hide();
            };
        }
    }, [handleClose]);

    return (
        <div className="modal fade" id={id} ref={modalRef}>
            <div className={clsx("modal-dialog", size === "lg" && "modal-xl")}>
                <div className="modal-content">
                    {successMessage ? (
                        <div className="bg-success p-3 d-flex text-white gap-3 align-items-center">
                            <span className="fas fa-check fs-1"></span>
                            <div className="h4 text-white">{successMessage}</div>
                            <div className="ms-auto">
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            <div className="modal-header">
                                <h2 className="h4">{title}</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>

                            <div className="modal-body">{children}</div>

                            {confirmBtnText && (
                                <div className="modal-footer justify-content-start d-flex gap-2 align-items-center">
                                    <button
                                        type={confirmBtnType}
                                        disabled={loading || disabled}
                                        className={clsx("btn btn-icon", confirmBtnClass)}
                                        onClick={() => {
                                            if (onConfirm) {
                                                onConfirm();
                                            }
                                        }}
                                    >
                                        {confirmBtnIcon && <span className={confirmBtnIcon}></span>}
                                        <span>{confirmBtnText}</span>
                                    </button>

                                    {secondaryBtnClass && secondaryIcon && secondaryText && onSecondaryConfirm && (
                                        <button
                                            type="button"
                                            className={secondaryBtnClass}
                                            onClick={onSecondaryConfirm}
                                            disabled={loading || disabled}
                                        >
                                            <IconText icon={secondaryIcon} text={secondaryText} />
                                        </button>
                                    )}

                                    {loading && <SpinnerBlock className="ms-auto" />}
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
