import { lazy, LazyExoticComponent } from "react";

const HomePage = lazy(() => import("pages/HomePage"));
const LoginPage = lazy(() => import("pages/account/LoginPage"));
const ApproveUserPage = lazy(() => import("pages/account/ApproveUserPage"));
const AcceptInvitationPage = lazy(() => import("pages/account/AcceptInvitationPage"));
const ChangePasswordPage = lazy(() => import("pages/account/ChangePasswordPage"));
const RecoverPasswordPage = lazy(() => import("pages/account/RecoverPasswordPage"));
const PanelPage = lazy(() => import("pages/PanelPage"));
const AdminAccessRequiredPage = lazy(() => import("pages/AdminAccessRequiredPage"));
const UpdateSchoolPage = lazy(() => import("pages/UpdateSchoolPage"));
const CreateSchoolPage = lazy(() => import("pages/CreateSchoolPage"));
const SubscriptionsPage = lazy(() => import("pages/subscriptions/SubscriptionsPage"));
const SubscriptionsAutoAssignPage = lazy(() => import("pages/subscriptions/SubscriptionsAutoAssignPage"));
const SubscriptionsPurchasePage = lazy(() => import("pages/subscriptions/SubscriptionsPurchasePage"));
const SubscriptionsPurchaseSuccessPage = lazy(() => import("pages/subscriptions/SubscriptionsPurchaseSuccessPage"));
const TeachersPage = lazy(() => import("pages/teachers/TeachersPage"));
const TeachersTeacherPage = lazy(() => import("pages/teachers/TeachersTeacherPage"));
const TeachersTeacherAssignPage = lazy(() => import("pages/teachers/TeachersTeacherAssignPage"));
const TeachersInvitePage = lazy(() => import("pages/teachers/TeachersInvitePage"));
const AdministratorsPage = lazy(() => import("pages/administrators/AdministratorsPage"));
const AdministratorsInvitePage = lazy(() => import("pages/administrators/AdministratorsInvitePage"));
const AdministratorsAdministratorPage = lazy(() => import("pages/administrators/AdministratorsAdministratorPage"));
const TermsPage = lazy(() => import("pages/TermsPage"));
const PrivacyPage = lazy(() => import("pages/PrivacyPage"));
const AboutUsPage = lazy(() => import("pages/AboutUsPage"));
const ProfileDetailsPage = lazy(() => import("pages/profile/ProfileDetailsPage"));

export const RouteList = {
    Login: "/login",
    ChangePassword: "/change-password",
    CreateSchool: "/create-school",
    UpdateSchool: "/update-school",
    Profile: "/profile",
    Subscriptions: "/subscriptions",
    SubscriptionsPurchase: "/subscriptions/purchase",
    SubscriptionsPurchaseSuccess: "/subscriptions/purchase/success",
    SubscriptionsAutoAssign: "/subscriptions/auto-assign",
    Teachers: "/teachers",
    TeachersInvite: "/teachers/invite",
    Administrators: "/administrators",
    AdministratorsInvite: "/administrators/invite",
    Terms: "/terms",
    Privacy: "/privacy",
    AboutUs: "/about-us",
    Panel: "/panel",
    Help: "/help",
    AdminAccessRequired: "/admin-access-required",
    Home: "/",
};

export const RouteLink = {
    approveUser: (hash: string) => `/approve-user/${hash}`,
    recoverPassword: (hash: string) => `/recover-password/${hash}`,
    teacher: (id: number) => `/teachers/${id}`,
    teacherAssign: (id: number) => `/teachers/${id}/assign`,
    administrator: (id: number) => `/administrators/${id}`,
    acceptInvitation: (hash: string) => `/accept-invitation/${hash}`,
};

interface IRouteTableItem {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: LazyExoticComponent<any>;
}

export const RouteTable: Array<IRouteTableItem> = [
    { path: "/approve-user/:hash", Component: ApproveUserPage },
    { path: "/teachers/:id([0-9]+)/assign", Component: TeachersTeacherAssignPage },
    { path: "/teachers/:id([0-9]+)", Component: TeachersTeacherPage },
    { path: "/administrators/:id([0-9]+)", Component: AdministratorsAdministratorPage },
    { path: "/accept-invitation/:hash", Component: AcceptInvitationPage },
    { path: "/recover-password/:hash", Component: RecoverPasswordPage },
    { path: RouteList.Home, Component: HomePage },
    { path: RouteList.Login, Component: LoginPage },
    { path: RouteList.Panel, Component: PanelPage },
    { path: RouteList.AdminAccessRequired, Component: AdminAccessRequiredPage },
    { path: RouteList.ChangePassword, Component: ChangePasswordPage },
    { path: RouteList.CreateSchool, Component: CreateSchoolPage },
    { path: RouteList.UpdateSchool, Component: UpdateSchoolPage },
    { path: RouteList.Subscriptions, Component: SubscriptionsPage },
    { path: RouteList.SubscriptionsAutoAssign, Component: SubscriptionsAutoAssignPage },
    { path: RouteList.SubscriptionsPurchase, Component: SubscriptionsPurchasePage },
    { path: RouteList.SubscriptionsPurchaseSuccess, Component: SubscriptionsPurchaseSuccessPage },
    { path: RouteList.Teachers, Component: TeachersPage },
    { path: RouteList.TeachersInvite, Component: TeachersInvitePage },
    { path: RouteList.Administrators, Component: AdministratorsPage },
    { path: RouteList.AdministratorsInvite, Component: AdministratorsInvitePage },
    { path: RouteList.Terms, Component: TermsPage },
    { path: RouteList.AboutUs, Component: AboutUsPage },
    { path: RouteList.Privacy, Component: PrivacyPage },
    { path: RouteList.Profile, Component: ProfileDetailsPage },
];

export const Redirects: Map<string, string> = new Map([]);
