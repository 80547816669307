import { EmailCommand } from "commands/EmailCommand";
import { GuidCommand } from "commands/GuidCommand";
import { AcceptInvitationCommand } from "commands/users/AcceptInvitationCommand";
import { ChangePasswordCommand } from "commands/account/ChangePasswordCommand";
import { EmailLoginCommand } from "commands/account/EmailLoginCommand";
import { RecoverPasswordCommand } from "commands/account/RecoverPasswordCommand";
import { SuccessResult } from "results/SuccessResult";
import { GetInvitationResult } from "results/users/GetInvitationResult";
import { GlobalDataResult } from "results/account/GlobalDataResult";
import { useApiService } from "services/ApiService";
import { SchoolLoginResult } from "results/account/SchoolLoginResult";
import { ApproveUserResult } from "results/account/ApproveUserResult";
import { AcceptInvitationResult } from "results/account/AcceptInvitationResult";
import { RecoverPasswordResult } from "results/account/RecoverPasswordResult";

export const AccountApiService = () => {
    const { get, post } = useApiService({ controller: "/account" });

    return {
        login: (command: EmailLoginCommand) => post<SchoolLoginResult>("/login", command),
        changePassword: (command: ChangePasswordCommand) => post<SuccessResult>("/change-password", command),
        approveUser: (command: GuidCommand) => post<ApproveUserResult>("/approve", command),
        forgotPassword: (command: EmailCommand) => post<SuccessResult>("/forgot-password", command),
        getRecoverPassword: (hash: string) => get<SuccessResult>(`/recover-password/${hash}`),
        recoverPassword: (command: RecoverPasswordCommand) => post<RecoverPasswordResult>("/recover-password", command),
        getInvitation: (hash: string) => get<GetInvitationResult>(`/invitation/${hash}`),
        getGlobalUserData: () => get<GlobalDataResult>("/global-data"),
        acceptInvitation: (command: AcceptInvitationCommand) =>
            post<AcceptInvitationResult>("/accept-invitation", command),
    };
};
