import { RouteList } from "Routes";
import { Link, NavLink, matchPath } from "react-router-dom";
import { Fragment, useContext } from "react";
import { GlobalContext } from "contexts/GlobalContext";
import { LogoutButton } from "components/buttons/LogoutButton";
import clsx from "clsx";

export const Navbar = () => {
    const { isAuthenticated, globalData } = useContext(GlobalContext);

    const isInProfile = matchPath(location.pathname, RouteList.Profile);

    return (
        <nav className="navbar navbar-expand-lg border-top border-bottom bg-body-secondary">
            <div className="container flex-row">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbar">
                    {isAuthenticated && (
                        <ul className="navbar-nav me-auto">
                            {globalData?.isSchool && (
                                <Fragment>
                                    <li className="nav-item">
                                        <NavLink to={RouteList.Subscriptions} className="nav-link">
                                            <span>🚀</span>
                                            <span className="nav-link__text">Subscriptions</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={RouteList.Teachers} className="nav-link">
                                            <span>👨‍🏫</span>
                                            <span className="nav-link__text">Teachers</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={RouteList.Administrators} className="nav-link">
                                            <span> 👑</span>
                                            <span className="nav-link__text">Administrators</span>
                                        </NavLink>
                                    </li>
                                </Fragment>
                            )}

                            {!globalData?.schoolId && !globalData?.isSchool && (
                                <li className="nav-item">
                                    <NavLink to={RouteList.CreateSchool} className="nav-link">
                                        <span>🏫</span>
                                        <span className="nav-link__text">Create school</span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    )}

                    <ul className="navbar-nav">
                        {isAuthenticated ? (
                            <Fragment>
                                <li className="nav-item dropdown" id="nav-profile">
                                    <button
                                        type="button"
                                        className={clsx("nav-link dropdown-toggle", isInProfile && "active")}
                                        data-bs-toggle="dropdown"
                                    >
                                        <span>🙋</span>
                                        <span className="nav-link__text">Profile</span>
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <Link className="dropdown-item" to={RouteList.Profile}>
                                                <span>🙋</span>
                                                <span className="nav-link__text">User details</span>
                                            </Link>
                                        </li>
                                        {globalData?.isSchool && (
                                            <li>
                                                <Link to={RouteList.UpdateSchool} className="dropdown-item">
                                                    <span>🏫</span>
                                                    <span className="nav-link__text">Update school</span>
                                                </Link>
                                            </li>
                                        )}
                                        <li>
                                            <Link className="dropdown-item" to={RouteList.ChangePassword}>
                                                <span>🔑</span>
                                                <span className="nav-link__text">Change password</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <LogoutButton className="dropdown-item" />
                                        </li>
                                    </ul>
                                </li>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={RouteList.Login}>
                                        <span>🔑</span>
                                        <span className="nav-link__text">School admin login</span>
                                    </NavLink>
                                </li>
                            </Fragment>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};
