import { BrowserRouter } from "react-router-dom";
import { GlobalContextProvider } from "contexts/GlobalContext";
import { Routes } from "components/Routes";

export const App = () => (
    <BrowserRouter>
        <GlobalContextProvider>
            <Routes />
        </GlobalContextProvider>
    </BrowserRouter>
);
