import clsx from "clsx";
import { Breadcrumbs } from "components/Breadcrumbs";
import { Footer } from "components/Footer";
import { Navbar } from "components/Navbar";
import { Prenav } from "components/Prenav";
import { GlobalContext } from "contexts/GlobalContext";
import { BreadcrumbList } from "data/BreadcrumbsData";
import { useScrollToTop } from "hooks/useScrollToTop";
import { ReactNode, useEffect, useContext, useState, PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import { RouteList } from "Routes";

type AuthType = "Public" | "AuthRequired" | "AuthBlocked";
type ViewType = "LoginRequired" | "Content";

export const Page = (
    props: PropsWithChildren<{
        title: string;
        description: string;
        canonical: string;
        authType: AuthType;
        imageUrl?: string;
        subNav?: ReactNode;
        useContainer?: boolean;
        noIndex?: boolean;
        breadcrumbs: BreadcrumbList;
    }>,
) => {
    const {
        children,
        title,
        description,
        canonical,
        subNav,
        authType,
        useContainer = true,
        noIndex,
        breadcrumbs,
    } = props;

    const { isAuthenticated } = useContext(GlobalContext);

    useScrollToTop();

    const history = useHistory();

    // handle view type
    const [viewType, setViewType] = useState<ViewType>();

    useEffect(() => {
        if (authType === "AuthBlocked" && isAuthenticated) {
            history.push(RouteList.Home);
        } else if (authType === "AuthRequired" && !isAuthenticated) {
            // auth required but user doesn't have it
            setViewType("LoginRequired");
        } else {
            setViewType("Content");
        }
    }, [authType, history, isAuthenticated]);

    // set the title
    useEffect(() => {
        const titleElement = document.querySelector("title");
        // const ogTitleElement = document.querySelector("meta[property='og:title']");

        const siteName = "School admin";

        if (titleElement) {
            // we only want to show the site name on home page
            const fullTitle = title.length ? `${title} - ${siteName}` : `${siteName} - Frankenstories & Writelike`;

            titleElement.textContent = fullTitle;
        }
    }, [title]);

    // set the description
    useEffect(() => {
        const descriptionElement = document.querySelector("meta[name='description']");
        const ogDescriptionElement = document.querySelector("meta[property='og:description']");

        if (descriptionElement) {
            descriptionElement.setAttribute("content", description ?? "");
        }

        if (ogDescriptionElement) {
            ogDescriptionElement.setAttribute("content", description ?? "");
        }
    }, [description]);

    // set canonical url
    useEffect(() => {
        const fullCanonical = `https://${window.location.host}${canonical}`;

        const canonicalElement = document.querySelector("link[rel='canonical']");
        const ogCanonicalElement = document.querySelector("meta[property='og:url']");

        if (canonicalElement) {
            canonicalElement.setAttribute("href", fullCanonical);
        }

        if (ogCanonicalElement) {
            ogCanonicalElement.setAttribute("content", fullCanonical ?? "");
        }
    }, [canonical]);

    // manage noindex tags
    useEffect(() => {
        const metaTag = document.querySelector("meta[name='robots'][content='noindex']");

        // add tag if required
        if (noIndex && !metaTag) {
            const newMetaTag = document.createElement("meta");
            newMetaTag.setAttribute("name", "robots");
            newMetaTag.setAttribute("content", "noindex");
            document.head.appendChild(newMetaTag);
        } else if (!noIndex && metaTag) {
            document.head.removeChild(metaTag);
        }
    }, [noIndex]);

    return (
        <div className="d-flex flex-grow-1 flex-column gap-4 gap-lg-5 h-100">
            <div className="d-print-none">
                <Prenav />
                <Navbar />
                {subNav}
                {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            </div>

            <div className={clsx(useContainer && "container", "d-flex flex-grow-1 position-relative")}>
                <div className="d-flex flex-grow-1 flex-column position-relative w-100">
                    {viewType === "LoginRequired" ? (
                        <div className="container-mini vstack gap-3">
                            <div className="vstack gap-3">
                                <h2>Login required</h2>
                            </div>
                        </div>
                    ) : (
                        children
                    )}
                </div>
            </div>

            <Footer />
        </div>
    );
};
