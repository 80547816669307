import { IdCommand } from "commands/IdCommand";
import { InviteSchoolAdminCommand } from "commands/schools/InviteSchoolAdminCommand";
import { AssignSubscriptionCommand } from "commands/users/AssignSubscriptionCommand";
import { AutoAssignSubscriptionsCommand } from "commands/users/AutoAssignSubscriptionsCommand";
import { CreateSchoolCommand } from "commands/users/CreateSchoolCommand";
import { InviteTeacherCommand } from "commands/users/InviteTeacherCommand";
import { UpdateSchoolCommand } from "commands/users/UpdateSchoolCommand";
import { CodeResult } from "results/CodeResult";
import { CommandResult } from "results/CommandResult";
import { IdResult } from "results/IdResult";
import { SuccessResult } from "results/SuccessResult";
import { SchoolLoginResult } from "results/account/SchoolLoginResult";
import { GetSchoolAccessRequiredResult } from "results/schools/GetSchoolAccessRequiredResult";
import { GetSchoolAdministratorsAdministratorResult } from "results/schools/GetSchoolAdministratorsAdministratorResult";
import { GetSchoolAdministratorsResult } from "results/schools/GetSchoolAdministratorsResult";
import { GetSchoolAssignSubscriptionToTeacherResult } from "results/schools/GetSchoolAssignSubscriptionToTeacherResult";
import { GetSchoolAssignTeacherToSubscriptionResult } from "results/schools/GetSchoolAssignTeacherToSubscriptionResult";
import { GetSchoolAutoAssignSubscriptionsResult } from "results/schools/GetSchoolAutoAssignSubscriptionsResult";
import { GetSchoolInvitedTeachersResult } from "results/schools/GetSchoolInvitedTeachersResult";
import { GetSchoolPanelResult } from "results/schools/GetSchoolPanelResult";
import { GetSchoolStudentResult } from "results/schools/GetSchoolStudentResult";
import { GetSchoolStudentsResult } from "results/schools/GetSchoolStudentsResult";
import { GetSchoolSubscriptionResult } from "results/schools/GetSchoolSubscriptionResult";
import { GetSchoolSubscriptionsResult } from "results/schools/GetSchoolSubscriptionsResult";
import { GetSchoolTeacherResult } from "results/schools/GetSchoolTeacherResult";
import { GetSchoolTeachersResult } from "results/schools/GetSchoolTeachersResult";
import { InviteSchoolAdminResult } from "results/schools/InviteSchoolAdminResult";
import { GetSchoolResult } from "results/users/GetSchoolResult";
import { InviteTeacherResult } from "results/users/InviteTeacherResult";
import { useApiService } from "services/ApiService";

export const SchoolsApiService = () => {
    const { get, post } = useApiService({ controller: "/panel" });

    return {
        getSchoolPanel: () => get<GetSchoolPanelResult>("/panel"),
        getSchoolSubscriptions: () => get<GetSchoolSubscriptionsResult>("/subscriptions"),
        getSchoolSubscription: (id: number) => get<GetSchoolSubscriptionResult>(`/subscription/${id}`),
        unassignSubscription: (id: number) => post("/subscription/unassign", { id }),
        assignSubscription: (command: AssignSubscriptionCommand) => post("/subscription/assign", command),
        getAssignSubscriptionToTeacher: (id: number) =>
            get<GetSchoolAssignSubscriptionToTeacherResult>(`/subscription/${id}/assign`),
        getAssignTeacherToSubscription: (id: number) =>
            get<GetSchoolAssignTeacherToSubscriptionResult>(`/teacher/${id}/assign`),
        getSchoolTeachers: () => get<GetSchoolTeachersResult>("/teachers"),
        getSchoolTeacher: (id: number) => get<GetSchoolTeacherResult>(`/teacher/${id}`),
        getSchoolStudents: () => get<GetSchoolStudentsResult>("/students"),
        getSchoolStudent: (id: number) => get<GetSchoolStudentResult>(`/student/${id}`),
        getSchoolInvitedTeachers: () => get<GetSchoolInvitedTeachersResult>("/teachers/invited"),
        inviteTeacher: (command: InviteTeacherCommand) => post<InviteTeacherResult>("/invite-teacher", command),
        getSchool: () => get<GetSchoolResult>("/school"),
        updateSchool: (command: UpdateSchoolCommand) => post("/update-school", command),
        createSchool: (command: CreateSchoolCommand) => post<SchoolLoginResult>("/create-school", command),
        regenerateSchoolCode: () => post<CodeResult>("/regenerate-school-code"),
        getAutoAssignSubscriptions: () => get<GetSchoolAutoAssignSubscriptionsResult>("/auto-assign-subscriptions"),
        autoAssignSubscriptions: (command: AutoAssignSubscriptionsCommand) =>
            post<SuccessResult>("/auto-assign-subscriptions", command),
        removeTeacher: (command: IdCommand) => post<CommandResult<IdResult>>("/remove-teacher", command),
        getAdministrators: () => get<GetSchoolAdministratorsResult>("/administrators"),
        getAdministrator: (id: number) => get<GetSchoolAdministratorsAdministratorResult>(`/administrator/${id}`),
        inviteAdmin: (command: InviteSchoolAdminCommand) => post<InviteSchoolAdminResult>("/invite-admin", command),
        removeAdministrator: (id: number) => post<CommandResult<IdResult>>(`/remove-administrator/${id}`),
        getAccessRequired: () => get<GetSchoolAccessRequiredResult>("/access-required"),
    };
};
