import { Link } from "react-router-dom";
import { RouteList } from "Routes";

export const Footer = () => (
    <div>
        <div className="container">
            <div className="hstack justify-content-center justify-content-lg-start">
                <div className="hstack gap-3 justify-content-between">
                    <ul className="hstack gap-3 list-unstyled">
                        <li>
                            <Link to={RouteList.AboutUs}>About us</Link>
                        </li>
                        <li>
                            <Link to={RouteList.Terms}>Terms</Link>
                        </li>
                        <li>
                            <Link to={RouteList.Privacy}>Privacy</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
);
