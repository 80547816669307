import moosend from "mootracker";

export const Moosend = {
    init: () => {
        moosend.init("3e842115-00ef-42ef-950f-4a37c0aacef9", false);
        moosend.trackPageView();
    },
    identify: (email: string) => {
        moosend.track("identify", { email });
    },
};
