import clsx from "clsx";
import { Toast } from "bootstrap";
import { Fragment, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "contexts/GlobalContext";
import { ToastModel } from "types/ToastModel";
import { useHistory } from "react-router-dom";

type ToastItemProps = {
    toast: ToastModel;
};

export const ToastItem = (props: ToastItemProps) => {
    const { toast } = props;
    const { id, type, title, description, hideIcon } = toast;

    const history = useHistory();

    const toastRef = useRef<HTMLDivElement>(null);

    // trigger toast on notification change
    useEffect(() => {
        if (toastRef.current) {
            const options: Partial<Toast.Options> = {};

            options.delay = 7000;

            Toast.getOrCreateInstance(toastRef.current, options)?.show();
        }
    }, []);

    // hide toast on page change
    useEffect(() => {
        history.listen(() => {
            if (toastRef.current) {
                Toast.getOrCreateInstance(toastRef.current)?.hide();
            }
        });
    }, [history]);

    return (
        <div
            key={id}
            ref={toastRef}
            className={clsx(
                "toast",
                type === "success" && "bg-success border-success",
                type === "failure" && "bg-danger border-danger",
            )}
        >
            <div
                className={clsx(
                    "toast-header text-white h6 hstack gap-3 justify-content-between",
                    type === "success" && "bg-success",
                    type === "failure" && "bg-danger",
                    type === "info" && ["bg-dark", !description && "border-0"],
                )}
            >
                <div className="d-flex gap-2 align-items-center">
                    {!hideIcon && (
                        <Fragment>
                            {type === "success" && <span className="fas fa-check"></span>}
                            {type === "failure" && <span className="fas fa-triangle-exclamation"></span>}
                        </Fragment>
                    )}
                    <span>{title}</span>
                </div>

                <button type="button" className="btn-close" data-bs-dismiss="toast"></button>
            </div>

            {description && (
                <div
                    className={clsx(
                        "toast-body vstack gap-3 text-white",
                        type === "success" && "bg-success",
                        type === "failure" && "bg-danger",
                        type === "info" && "bg-dark",
                    )}
                >
                    {description && <div>{description}</div>}
                </div>
            )}
        </div>
    );
};

export const ToastContainer = () => {
    const { notifications } = useContext(GlobalContext);

    return (
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
            {notifications.map(t => (
                <ToastItem toast={t} key={t.id} />
            ))}
        </div>
    );
};
